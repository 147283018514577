import React from 'react';
import "../components/style.css";


function Footer() {
  return (
  <div className='footer'>
    <footer>
    <small>
      Copyright © 2023 Hassan-Nur - Designer / Developer / Inventor. All Rights Reserved.
    </small>
  </footer>

  </div>
  )
}

export default Footer
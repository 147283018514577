import React from 'react'
import { Outlet } from 'react-router'
import NavBar from './NavBar'
import {Provider} from "react-redux";
import store from '../store/store';
import Footer from './Footer';



const RouteLayout = () => {
  return (
    <>
  <Provider store={store}>
  <NavBar/>
    <main>
        <Outlet/>
    </main>
    <Footer/>
  </Provider>
    </>
  )
}

export default RouteLayout
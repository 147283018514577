import {configureStore} from "@reduxjs/toolkit";
import cartSlice from "../store/cartSlice";
import getProducts from "../store/productSlice";



const store = configureStore({
    reducer : {
        cart: cartSlice,
        products: getProducts

    }

});


export default store;




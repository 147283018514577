import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";




const initialState = {
    data: [],
}
const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        
    },
    reducer:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(getProducts.fulfilled, (state,action) => {
            state.data = action.payload
        })
    }

});



export default productSlice.reducer;

export const getProducts = createAsyncThunk("products/get" ,  async() => {
    const data = await fetch("https://fakestoreapi.com/products")
    const result = await data.json();
    return result;
})